<template>
  <cw-page
    icon="border_color"
    class="cw-business-loan-signees"
  >
    <template #hero-title>
      <translate>
        The beneficial owners
      </translate>
    </template>
    <template>
      <owners
        v-bind="{
          form,
          onRemoveItem,
          validated,
        }"
        class="mt-6"
      />

      <signees-dialog/>
    </template>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwSmePosOwnersInformation',

  components: {
    owners: () => import('@fi/pages/steps/businessLoan/BusinessLoanSigneesOwners'),
    'signees-dialog': () => import('@fi/pages/steps/businessLoan/BusinessLoanSigneesDialog'),
  },

  mixins: [goToError],

  data: () => ({
    form: {
      owners: [],
    },
    validOwnership: false,
    validated: false,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
    }),
  },

  watch: {
    initialData: {
      handler(data) {
        if (!data || !data.owners || this.form.owners.length) return;

        this.form.owners = this.clone(data.owners);
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    this.$bus.$on(
      'SIGNEES_ITEM_ADD',
      ({ type, data }) => this.onAddItem({ type, data }),
    );

    this.$bus.$on(
      'SIGNEES_ITEM_REMOVE',
      ({ item, type }) => this.onRemoveItem({ item, type }),
    );

    this.$bus.$on(
      'SIGNEES_CONFIRM_REMOVAL',
      ({ id, type }) => this.onConfirmRemoval({ id, type }),
    );

    this.$bus.$on('SIGNEES_DIALOG_OWNESHIP_STATUS', (valid) => {
      this.validOwnership = valid;
    });
  },

  methods: {
    ...mapActions({
      getApplication: 'application/getApplication',
      setData: 'application/setData',
      submitForm: 'application/submit',
    }),

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    async onAddItem({ type, data }) {
      const {
        $bus,
        $wait,
        form,
        setData,
      } = this;

      const params = {
        formKey: `${type}s`,
        item: this.clone(data),
      };

      // prevent duplicates
      if (form[params.formKey].findIndex(o => o.ssn === params.item.ssn) > -1) return;

      try {
        $wait.start('signees__add-item');

        const options = {
          [params.formKey]: [...form[params.formKey], params.item],
        };

        await setData(options);

        // close dialog and wait animation to finish
        $bus.$emit('SIGNEES_DIALOG_CLOSE');
        await this.wait(500);

        // add signee to the form only if setData has succeeded
        // to prevent mixed signals to the user
        form[params.formKey].push(this.clone(params.item));

        $wait.end('signees__add-item');
      } catch (error) {
        $wait.end('signees__add-item');
        console.error(error);
      }
    },

    onRemoveItem({ item, type }) {
      const components = {
        signee: 'DialogSigneeRemoval',
        owner: 'DialogOwnerRemoval',
      };

      this.$bus.$emit('OPEN_CONFIRMATION_DIALOG', {
        component: components[type],
        folder: 'fi',
        props: { item },
      });
    },

    async onConfirmRemoval({ id, type }) {
      const {
        form,
      } = this;

      const keys = {
        signee: 'signees',
        owner: 'owners',
      };

      const key = keys[type];

      form[key] = form[key].filter(s => s.ssn !== id);

      try {
        await this.setData({
          [key]: form[key],
        });
      } catch (error) {
        console.error(error);
      }

      this.$bus.$emit('SIGNEES_DIALOG_CLOSE');
      this.$bus.$emit('CLOSE_CONFIRMATION_DIALOG');
    },

    async submit() {
      try {
        this.$wait.start('SUBMIT_FORM');
        const params = { // eslint-disable-line
          formParams: this.form,
          routineParams: {
            uri: 'executeRoutine',
            routine: 'Submit Owners Information Page',
          },
        };

        await this.submitForm(params);
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },

    async validateForm() {
      this.validated = true;
      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });

      DataLayer.onValidationClick();

      if (!this.validOwnership) {
        this.goToError();
        DataLayer.onValidationFail();
        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });
      await this.submit();
    },

    async wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
};
</script>
